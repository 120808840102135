import useJwt from '@/auth/jwt/useJwt'

export const api = ({ method, args = {}, freeze = true }) => new Promise((resolve, reject) => useJwt.api(method, args)
  .then(response => resolve(response.data))
  .catch(error => reject(error)))
export const getGetAvailabilities = (service_unit, date, doctor) => api({
  method: 'clinical.api.appointment.appointment.check_available_doctors',
  args: { service_unit, date, doctor },
})

export const getServiceUnitPractitioners = service_unit => api({
  method: 'clinical.api.appointment.appointment.get_doctors_in_service_unit',
  args: { service_unit },
})

export const checkSlotsAvailablePractitioners = (serviceunit, date, doctor) => api({
  method: 'clinical.api.appointment.appointment.check_available_practitioners_based_on_slots',
  args: { serviceunit, date, doctor },
})

export const checkTurnedUpBookings = (serviceunit, date) => api({
  method: 'clinical.api.appointment.appointment.cap_max_number_of_turned_up',
  args: { serviceunit, date },
})

export const creditedTurnUp = (appointment) =>
  api({
    method: "billing.billing.api.patient.patient_balance.credited_turn_up",
    args: { appointment },
  });

export const addPatientToQueue = (appointment) =>
  api({
    method: "billing.billing.api.patient.patient_balance.add_emergency_patient_to_queue",
    args: { appointment },
  });

export const updateAppointmentStatus = (appointment_id, status) =>
  api({
    method: "healthcare.healthcare.doctype.patient_appointment.patient_appointment.update_status",
    args: { appointment_id, status },
  });

export const admitEmergencyPatient = (medical_department, appointment, bed) =>
  api({
    method: "clinical.api.patient_encounter.patient_encounter.admit_emergency_patient",
    args: { medical_department, appointment, bed },
  });

  export const emergency = (appointment) =>
    api({
        method: "billing.billing.api.patient.patient_balance.mark_emergency",
        args: { appointment },
    }); 

export const savePrePayment = ({
  customer_name,
  amount,
  account_for_change,
  change_amount = 0,
  amount_tendered,
  patient_name,
  reference_no,
  mode_of_payment,
}) => api({
  method: 'billing.billing.api.accounts.patients.create_prepayment',
  args: {
    customer_name,
    amount,
    account_for_change,
    change_amount,
    amount_tendered,
    reference_no,
    patient_name,
    mode_of_payment,
  },
})
