<template>
  <b-container>
    <b-modal
      id="modal-center-2"
      centered
      :title="`${rowItem.patient_name} - ${id}`"
    >

      <b-list-group>
        <b-list-group-item  @click="goToAppointment()">
          Create New  Appointment
        </b-list-group-item>
        <b-list-group-item @click="goToPatientDetails()">
           View Patient Details
        </b-list-group-item>
        <b-list-group-item @click="goToPatientAccounts()">
           Open Billing
        </b-list-group-item>
        <b-list-group-item >   <receive-payment
          class="w-100"
          :is-button="false"
          :patient-number="id"
        /></b-list-group-item>
      </b-list-group>

    </b-modal>

    <b-row
      style="width:100%"
      class="mx-auto mt-"
    >
      <list-component
        style="width:100%"
        :doctype="doctype"
        :fields="fields"
        @selected="rowSelected"
      />
    </b-row>
  </b-container>
</template>

<script>

import ListComponent from '@/views/components/doctype-list/ListComponent.vue'
import ReceivePayment from '@/views/erp/records/appointment/ReceivePayment.vue'

export default {
  name: 'Patients',
  components: { ListComponent, ReceivePayment },
  data() {
    return {
      doctype: 'Patient',
      title: 'Patients',
      subTitle: '',
      fields: ['name', 'patient_name', 'sex', 'dob', 'status'],
      id: '',
      rowItem: {}
    }
  },mounted(){
     this.setTitle("Patient List")
  },
  methods: {
    goToPatientAccounts(){
      const { id } = this
      this.$router.push({ name: 'patient-account', params: { id } })
    },
    goToAppointment() {
      const { id } = this
      this.$router.push({ name: 'book-appointment-patient', params: { patient: id } })
    },
    goToPatientDetails() {
      const { id } = this
      this.$router.push({ name: 'patient-view-actions', params: { id } })
    },

    rowSelected(item) {
      if (item && item.length) {
        this.id = item[0].name
        this.rowItem = item[0];
        // this.goToPatientDetails();
        this.$bvModal.show('modal-center-2')
      }
    },
  },
}
</script>

<style></style>
