<template>
  <div  @click="modalVisible = true">
    <b-button
      v-if="isButton"
      variant="outline-secondary"
      size="sm"
    >
     Make Payment
    </b-button>
    <span
      v-else
      class="w-100"
    > Receive Payment</span>
    <b-modal
      v-model="modalVisible"
      :title="`Add Payment Entry - ${ patientNumber}- ${patientFullName}`"
      @ok="submitPayment"
      @cancel="cancelPayment"
    >

      <b-form-group label="Mode of Payment">
        <doctype-link
          style="margin-left: 0px;"
          :doctype="'Mode of Payment'"
          @selected="updateMOP"
        />
      </b-form-group>
      <b-form-group label="Reference">
        <b-form-input v-model="paymentEntry.referenceNo" />
      </b-form-group>
      <b-form-group label="Amount">
        <b-form-input
          v-model="paymentEntry.paidAmount"
          type="number"
        />
      </b-form-group>
      <b-form-group label="Confirm Amount">
        <b-form-input
          v-model="paymentEntry.confirmAmount"
          type="number"
          @input="updateTenderedAmount"
        />
      </b-form-group>
      <b-form-group label="Amount Tendered">
        <b-form-input v-model="paymentEntry.tenderedAmount" />
      </b-form-group>

      <hr>
      <h4> CHANGE : <span style="color: red;"> {{ `KES ${paymentEntry.tenderedAmount - paymentEntry.paidAmount}`
      }}</span></h4>
    </b-modal>
  </div>
</template>

<script>
import {
  savePrePayment,
} from './availability.js'

export default {
  props: {
    patientNumber: String,
    isButton: { default: true },
  },
  data() {
    return {
      modalVisible: false,
      patientFullName: '',
      paymentEntry: {
        tableContainer: '',
        modeOfPayment: '',
        referenceNo: '',
        paidAmount: 0,
        confirmAmount: 0,
        tenderedAmount: 0,

      },
    }
  },
  computed: {
    paymentModes() {
      return ['Mode1', 'Mode2', 'Mode3']
    },

  },

  watch: {
    patientNumber() {
      this.updatePatienFullName()
    },
  },
  mounted() {
    this.updatePatienFullName()
  },
  methods: {
    updateMOP(result) {
      this.paymentEntry = { ...this.paymentEntry, modeOfPayment: result }
    },
    async updatePatienFullName() {
      if (this.patientNumber) {
        this.patientFullName = await this.docGetVal('Patient', this.patientNumber, 'patient_name')
      }
    },
    showPaymentModal() {
      // Set initial values and show the modal
      this.paymentEntry.tableContainer = '' // Set your initial HTML value here
      this.paymentEntry.party = `${this.patientName} (${this.customerNumber})`
      this.paymentEntry.modeOfPayment = ''
      this.paymentEntry.referenceNo = ''
      this.paymentEntry.paidAmount = 0
      this.paymentEntry.confirmAmount = 0
      this.paymentEntry.tenderedAmount = 0
      this.modalVisible = true
    },
    updateTenderedAmount() {
      // Update "Amount Tendered" when "Confirm Amount" changes
      this.paymentEntry.tenderedAmount = this.paymentEntry.confirmAmount
    },
    async submitPayment() {
      this.modalVisible = false
      const confirmed = await this.$bvModal.msgBoxConfirm(`Are you sure want to Post a payment of ${this.formatCurrency(this.paymentEntry.paidAmount)}`, {
        title: 'Confirmation Payment Entry',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'primary',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
      })

      if (confirmed) {
        const customer = await this.docGetVal('Patient', this.patientNumber, 'customer')
        try {
          const payment = await savePrePayment({
            customer_name: customer,
            patient_name: this.patientNumber,
            amount: this.paymentEntry.paidAmount,
            change_amount: this.paymentEntry.tenderedAmount - this.paymentEntry.paidAmount,
            amount_tendered: this.paymentEntry.tenderedAmount,
            reference_no: this.paymentEntry.referenceNo,
            mode_of_payment: this.paymentEntry.modeOfPayment,
          })

          if (payment != null) {
            this.show_alert('Payment successfully Posted')
          }
        } catch (e) {

        }
      }
    },
    amountToWords(amount) {
      const [dollars, cents = 0] = amount.toString().split('.').map(Number)

      const words = num => {
        const [units, teens, tens] = [['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'], ['', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'], ['', 'ten', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety']]
        return num === 0 ? 'zero' : num < 10 ? units[num] : num < 20 ? teens[num - 10] : `${tens[Math.floor(num / 10)]} ${units[num % 10]}`
      }

      return `${words(dollars)} KES${cents ? ` and ${words(cents)} cents` : ''}`
    },
    formatCurrency(input) {
      const number = typeof input === 'string' ? parseFloat(input) : input

      if (isNaN(number)) {
        return 'Invalid input'
      }

      const roundedNumber = Math.round(number)
      const formattedNumber = roundedNumber.toLocaleString('en-US')

      return `KES ${formattedNumber}`
    },
    cancelPayment() {
      // Close the modal without submitting
      this.modalVisible = false
    },
  },
}
</script>
